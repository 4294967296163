exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktivera-js": () => import("./../../../src/pages/aktivera.js" /* webpackChunkName: "component---src-pages-aktivera-js" */),
  "component---src-pages-aktivera-matteberget-js": () => import("./../../../src/pages/aktivera-matteberget.js" /* webpackChunkName: "component---src-pages-aktivera-matteberget-js" */),
  "component---src-pages-havsaventyret-forelasningar-js": () => import("./../../../src/pages/havsaventyret/forelasningar.js" /* webpackChunkName: "component---src-pages-havsaventyret-forelasningar-js" */),
  "component---src-pages-havsaventyret-index-js": () => import("./../../../src/pages/havsaventyret/index.js" /* webpackChunkName: "component---src-pages-havsaventyret-index-js" */),
  "component---src-pages-havsaventyret-laromedel-js": () => import("./../../../src/pages/havsaventyret/laromedel.js" /* webpackChunkName: "component---src-pages-havsaventyret-laromedel-js" */),
  "component---src-pages-havsaventyret-sa-funkar-det-js": () => import("./../../../src/pages/havsaventyret/sa-funkar-det.js" /* webpackChunkName: "component---src-pages-havsaventyret-sa-funkar-det-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matte-admin-js": () => import("./../../../src/pages/matte-admin.js" /* webpackChunkName: "component---src-pages-matte-admin-js" */),
  "component---src-pages-matteberget-forelasningar-js": () => import("./../../../src/pages/matteberget/forelasningar.js" /* webpackChunkName: "component---src-pages-matteberget-forelasningar-js" */),
  "component---src-pages-matteberget-index-js": () => import("./../../../src/pages/matteberget/index.js" /* webpackChunkName: "component---src-pages-matteberget-index-js" */),
  "component---src-pages-matteberget-sa-funkar-det-js": () => import("./../../../src/pages/matteberget/sa-funkar-det.js" /* webpackChunkName: "component---src-pages-matteberget-sa-funkar-det-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referenser-js": () => import("./../../../src/pages/referenser.js" /* webpackChunkName: "component---src-pages-referenser-js" */),
  "component---src-pages-resend-admin-js": () => import("./../../../src/pages/resend-admin.js" /* webpackChunkName: "component---src-pages-resend-admin-js" */),
  "component---src-pages-subscribem-components-inputeditcomponent-js": () => import("./../../../src/pages/subscribem/components/inputeditcomponent.js" /* webpackChunkName: "component---src-pages-subscribem-components-inputeditcomponent-js" */),
  "component---src-pages-subscribem-components-instruktionsfilmer-js": () => import("./../../../src/pages/subscribem/components/instruktionsfilmer.js" /* webpackChunkName: "component---src-pages-subscribem-components-instruktionsfilmer-js" */),
  "component---src-pages-subscribem-components-intro-js": () => import("./../../../src/pages/subscribem/components/intro.js" /* webpackChunkName: "component---src-pages-subscribem-components-intro-js" */),
  "component---src-pages-subscribem-components-library-js": () => import("./../../../src/pages/subscribem/components/library.js" /* webpackChunkName: "component---src-pages-subscribem-components-library-js" */),
  "component---src-pages-subscribem-components-login-js": () => import("./../../../src/pages/subscribem/components/login.js" /* webpackChunkName: "component---src-pages-subscribem-components-login-js" */),
  "component---src-pages-subscribem-components-logout-js": () => import("./../../../src/pages/subscribem/components/logout.js" /* webpackChunkName: "component---src-pages-subscribem-components-logout-js" */),
  "component---src-pages-subscribem-components-navigation-js": () => import("./../../../src/pages/subscribem/components/navigation.js" /* webpackChunkName: "component---src-pages-subscribem-components-navigation-js" */),
  "component---src-pages-subscribem-components-students-js": () => import("./../../../src/pages/subscribem/components/students.js" /* webpackChunkName: "component---src-pages-subscribem-components-students-js" */),
  "component---src-pages-subscribem-components-subscriber-js": () => import("./../../../src/pages/subscribem/components/subscriber.js" /* webpackChunkName: "component---src-pages-subscribem-components-subscriber-js" */),
  "component---src-pages-subscribem-index-js": () => import("./../../../src/pages/subscribem/index.js" /* webpackChunkName: "component---src-pages-subscribem-index-js" */),
  "component---src-pages-subscriber-components-gamelockmap-js": () => import("./../../../src/pages/subscriber/components/gamelockmap.js" /* webpackChunkName: "component---src-pages-subscriber-components-gamelockmap-js" */),
  "component---src-pages-subscriber-components-inputeditcomponent-js": () => import("./../../../src/pages/subscriber/components/inputeditcomponent.js" /* webpackChunkName: "component---src-pages-subscriber-components-inputeditcomponent-js" */),
  "component---src-pages-subscriber-components-intro-js": () => import("./../../../src/pages/subscriber/components/intro.js" /* webpackChunkName: "component---src-pages-subscriber-components-intro-js" */),
  "component---src-pages-subscriber-components-library-js": () => import("./../../../src/pages/subscriber/components/library.js" /* webpackChunkName: "component---src-pages-subscriber-components-library-js" */),
  "component---src-pages-subscriber-components-login-js": () => import("./../../../src/pages/subscriber/components/login.js" /* webpackChunkName: "component---src-pages-subscriber-components-login-js" */),
  "component---src-pages-subscriber-components-logout-js": () => import("./../../../src/pages/subscriber/components/logout.js" /* webpackChunkName: "component---src-pages-subscriber-components-logout-js" */),
  "component---src-pages-subscriber-components-navigation-js": () => import("./../../../src/pages/subscriber/components/navigation.js" /* webpackChunkName: "component---src-pages-subscriber-components-navigation-js" */),
  "component---src-pages-subscriber-components-students-js": () => import("./../../../src/pages/subscriber/components/students.js" /* webpackChunkName: "component---src-pages-subscriber-components-students-js" */),
  "component---src-pages-subscriber-components-subscriber-js": () => import("./../../../src/pages/subscriber/components/subscriber.js" /* webpackChunkName: "component---src-pages-subscriber-components-subscriber-js" */),
  "component---src-pages-subscriber-index-js": () => import("./../../../src/pages/subscriber/index.js" /* webpackChunkName: "component---src-pages-subscriber-index-js" */),
  "component---src-pages-theboattrip-forelasningar-js": () => import("./../../../src/pages/theboattrip/forelasningar.js" /* webpackChunkName: "component---src-pages-theboattrip-forelasningar-js" */),
  "component---src-pages-theboattrip-gdpr-js": () => import("./../../../src/pages/theboattrip/gdpr.js" /* webpackChunkName: "component---src-pages-theboattrip-gdpr-js" */),
  "component---src-pages-theboattrip-index-js": () => import("./../../../src/pages/theboattrip/index.js" /* webpackChunkName: "component---src-pages-theboattrip-index-js" */),
  "component---src-pages-theboattrip-sa-funkar-det-js": () => import("./../../../src/pages/theboattrip/sa-funkar-det.js" /* webpackChunkName: "component---src-pages-theboattrip-sa-funkar-det-js" */),
  "component---src-pages-user-admin-js": () => import("./../../../src/pages/user-admin.js" /* webpackChunkName: "component---src-pages-user-admin-js" */)
}

